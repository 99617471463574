<template>
  <div style="background: #F7F8FA">
    <Header></Header>
    <!-- 行业分类 -->
    <div class="exibit">
      <div class="search2">
        <div class="top3">
          <p class="title3">{{ $t('industry') }}</p>
          <div
          class="items"
          :style="{height: itemheight, overflow: 'hidden'}"
          >
            <p :class="queryParam.categoryId == item.categoryId ? 'activeClass item' : 'item'" v-for="(item, index) in pcategory" :key="index" @click="changeParentId(item.categoryId)">{{ item.categoryName }}</p>
            <p class="arrow" @click="run()">
              <a-icon :type="arrow" />
            </p>
          </div>
        </div>
        <div class="middle" v-if="childCategory.length > 0">
          <p :class="queryParam.childCategoryId == item.categoryId ? 'activeClass item' : 'item'" v-for="(item, index) in childCategory" :key="index" @click="changeChildId(item.categoryId)">{{ item.categoryName }}</p>
        </div>
        <div class="bom" v-if="countries.length > 0">
          <p class="title3">{{ $t('area') }}</p>
          <div class="items">
            <p :class="queryParam.countryId == item.countryId ? 'activeClass item' : 'item'" v-for="item in countries" :key="item.countryId" @click="changeCountry(item.countryId)">{{ item.countryName }}</p>
          </div>
        </div>
        <div class="bom" v-if="dates.length > 0">
          <p class="title3">{{ $t('data') }}</p>
          <div class="items">
            <p :class="queryParam.date == item.startMonthValue ? 'activeClass item' : 'item'" v-for="(item, index) in dates" :key="index" @click="changeDate(item.startMonthValue)">{{ item.startMonthFormat }}</p>
          </div>
        </div>
        <div class="bom" v-if="statuslist.length > 0">
          <p class="title3">{{ $t('status') }}</p>
          <div class="items">
            <p :class="queryParam.status == item.statusValue ? 'activeClass item' : 'item'" v-for="(item, index) in statuslist" :key="index" @click="changeStatus(item.statusValue)">{{ item.statusText }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 展会 -->
    <div class="einfo1">
      <div class="einfo2">
        <a-spin v-if="loading" style="padding-top: 146px" />
        <template v-else>
          <template v-if="list.length > 0">
            <div class="einfo">
              <div v-for="item in list" :key="item.exhibitionId" @click="goto('Exhibi', { id: item.exhibitionId }, 'blank')">
                <div class="eimg">
                  <img :src="item.thumb" alt="">
                </div>
                <div class="e1">{{ item.country }}</div>
                <div class="e2">{{ item.exhibitionName }}</div>
                <div class="e3">
                  <div class="eleft">
                    <div class="el1">{{ item.time }}</div>
                    <div class="el2">{{ item.address }}</div>
                  </div>
                  <div class="eright"> {{ $t('enter') }} </div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="page">
              <a-pagination
                showQuickJumper
                :defaultCurrent="pagination.defaultCurrent"
                :defaultPageSize="pagination.defaultPageSize"
                :total="pagination.total"
                @change="onChange"
                @showSizeChange="onShowSizeChange"
              />
            </div>
          </template>
          <template v-else>
            <a-empty style="padding-top: 146px" />
          </template>
        </template>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import "@/assets/css/category.less";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "category",
  inject: ['reload'], // 注入依赖
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      loading: false,
      arrow: 'up',
      itemheight: 'auto',
      pcategory: [],
      childCategory: [],
      countries: [], // 国家
      dates: [], // 日期
      statuslist: [], // 状态
      queryParam: {
        categoryId: '',
        childCategoryId: '',
        countryId: '',
        date: '',
        keywords: '',
        status: ''
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 15, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
      //展会数据
      list: [],
      page: 1,
      categoryId: this.$route.params.categoryId,
      childCategoryId: this.$route.params.childId,
      date: this.$route.params.date,
      countryId: this.$route.params.countryId,
      keywords: this.$route.params.keywords,
      status: this.$route.params.status,
    }
  },
  mounted () {
    // 获取一级分类
    this.getCategory(0, 1)
    // 获取国家
    this.getCountry()
    // 获取日期
    this.getDate()
    // 获取状态
    this.getStatus()
    // 获取列表
    if (this.categoryId != undefined){
       this.queryParam.categoryId =  this.categoryId
       this.getCategory(this.categoryId, 2)
    }
    if (this.childCategoryId != undefined){
       this.queryParam.childCategoryId =  this.childCategoryId
    }
    if (this.childCategoryId != undefined){
       this.queryParam.childCategoryId =  this.childCategoryId
    }
     if (this.date != undefined){
       this.queryParam.date =  this.date
    }
    if (this.countryId != undefined){
       this.queryParam.countryId =  this.countryId
    }
    if (this.keywords != undefined){
       this.queryParam.keywords =  this.keywords
    }
    if (this.status != undefined){
       this.queryParam.status =  this.status
    }
    this.getList()
  },
  methods: {
    run() {
      if (this.arrow == 'up') {
        this.arrow = 'down';
        this.itemheight = '40px';
      } else {
        this.arrow = 'up';
        this.itemheight = 'auto';
      }
    },
    // 获取分类 parentId: 上级id，level：级别
    getCategory (parentId, level) {
      this.childCategory = []
      this.$common.fetchList('/exhibition/category/lists', { parentId: parentId }).then(data => {
        if (data.code === 200) {
          const result = data.data
          if (level === 1) {
            this.pcategory.push({
              categoryId: '',
              categoryName: this.$t('all')
            })
          } else {
            this.childCategory.push({
              categoryId: '',
              categoryName: this.$t('all')
            })
          }
          result.forEach(item => {
            if (level === 1) {
              this.pcategory.push(item)
            } else {
              this.childCategory.push(item)
            }
          })
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    // 改变一级
    changeParentId (id) {
      this.queryParam.categoryId = id
       if (id > 0) {
        this.getCategory(id, 2)
        this.queryParam.childCategoryId = ''
      } else {
        this.childCategory = []
        this.queryParam.childCategoryId = ''
      }
      this.getList()
    },
    // 改变子级
    changeChildId (id) {
      this.queryParam.childCategoryId = id
    },
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    },
    // 获取国家
    getCountry () {
      this.$common.fetchList('/exhibition/condition/country', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.countries.push({
            countryId: '',
            countryName: this.$t('all')
          })
          result.forEach(item => {
            this.countries.push(item)
          })
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    // 获取日期
    getDate () {
      this.$common.fetchList('/exhibition/condition/date', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.dates.push({
            startMonthValue: '',
            startMonthFormat: this.$t('all')
          })
          result.forEach(item => {
            this.dates.push(item)
          })
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    // 获取状态
    getStatus () {
      this.$common.fetchList('/exhibition/condition/status', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.statuslist.push({
            statusValue: '',
            statusText: this.$t('all')
          })
          result.forEach(item => {
            this.statuslist.push(item)
          })
          // const all = {
          //   statusValue: '',
          //   statusText: this.$t('all')
          // }
          // this.statuslist = [all, ...result]
          // console.log(this.statuslist)
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    // 改变国家
    changeCountry (id) {
      this.queryParam.countryId = id
    },
    // 改变日期
    changeDate (value) {
      this.queryParam.date = value
    }, 
    // 改变状态
    changeStatus (value) {
      this.queryParam.status = value
    },
    getList() {
      this.loading = true
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize
      }
      this.$common.fetchList('/exhibition/lists', Object.assign({}, params, this.queryParam)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.list = result.lists
          this.pagination = pagination
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
      .finally(() => {
        this.loading = false
      })
    },
    // 链接地址跳转
    goto (name, params = {}, type = 'current') {
      // 跳转到目的路由
      // 打开新页面
      if (type == 'blank') {
        const link = this.$router.resolve({ name: name, params: params })
        window.open(link.href, '_blank')
      } else if (type == 'current') { // 当前页面打开
        this.$router.push({ name: name, params: params })
      }
    }
  },
  watch: {
    'queryParam.childCategoryId' (newVal, oldVal) {
      this.getList()
    },
    'queryParam.countryId' (newVal, oldVal) {
      this.getList()
    },
    'queryParam.date' (newVal, oldVal) {
      this.getList()
    },
    'queryParam.status' (newVal, oldVal) {
      this.getList()
    },
    '$route.params.categoryId' (newVal, oldVal) {
      this.reload()
    },
    '$route.params.childId' (newVal, oldVal) {
      this.reload()
    },
     '$route.params.keywords' (newVal, oldVal) {
      this.reload()
    }
  }
};
</script>
